<template>
  <div class="flex flex-col lg:flex-row gap-x-12">
    <div class="w-full lg:w-2/3">
      <div class="bg-white p-6 pt-8 sm:p-8 sm:pt-12 md:p-12 md:pt-16">
        <h1
          class="flex flex-col text-black font-headings text-2xl sm:text-3xl md:text-4xl mb-8 sm:mb-12"
        >
          <span v-text="$t('title.about_zodiac_signs')" />
          <span
            class="bg-red-500 h-px inline-block w-16 mt-3"
            style="height: 2px"
          />
        </h1>
        <div class="text-content">
          <div v-for="(item, index) in zodiacSigns" :key="item.name">
            <div class="flex flex-col sm:flex-row">
              <div class="w-full sm:mr-8 flex-shrink-0 mb-6 sm:w-32 sm:mb-0">
                <inline-svg
                  :src="item.thumb"
                  class="text-yellow-500 fill-current h-16 sm:h-18 md:h-24 w-auto"
                />
              </div>
              <div class="w-full sm:w-auto">
                <h4 v-text="item.name" />
                <p class="font-headings italic text-sm">
                  {{ item.startDate | formatDate }} /
                  {{ item.endDate | formatDate }}
                </p>
                <div v-html="item.description"></div>
              </div>
            </div>
            <hr class="hidden sm:block" v-if="index < zodiacSigns.length - 1" />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:w-1/3 lg:pl-5">
      <div class="sticky top-0 pt-8">
        <sidebar-cta />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarCta from "../../components/SidebarCta.vue";
import i18n from "@/i18n.js";
export default {
  components: { SidebarCta },
  name: "WhyTheAstralTheme",
  computed: {
    zodiacSigns() {
      return this.$store.state.zodiacSigns;
    },
  },
  filters: {
    formatDate: function(value) {
      if (!value) return "";
      const [month, day] = value.split(".");
      value = new Date(2000, month - 1, day, 0, 0, 0);

      return value.toLocaleString(i18n.t("date_format"), {
        day: "numeric",
        month: "short",
      });
    },
  },
  metaInfo() {
    return { title: this.$t("title.about_zodiac_signs") };
  },
};
</script>
